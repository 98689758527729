/* You can add global styles to this file, and also import other style files */

@import "assets/css/custom.scss";
@import "styles/material-theme";
@import "styles/bootstrap";
@import "styles/admin.scss";
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap");
@import "src/styles/variables.scss";

::-webkit-scrollbar {
	width: 11px;
	height: 11px;
	margin: 5px;
	padding: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 4px #9a9a9a;
	border-radius: 10px;
	margin: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #3c6980;
	border-radius: 10px;
	box-shadow: inset 0 0 4px #354952;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #b30000;
}

html,
body {
	height: 100%;
}

body {
	margin: 0;
	font-family: Roboto, "Helvetica Neue", sans-serif;
	background-color: #{$aps-color-background}; //#f5f5f5//#D8D8D8;
}

body::-webkit-scrollbar {
	width: 0.3rem !important;
	transition: 300ms ease !important;
}

body::-webkit-scrollbar-track {
	background: none !important;
	margin: 0px !important;
}

body::-webkit-scrollbar-thumb {
	background: #cccccc !important;
}

body::-webkit-scrollbar-thumb:hover {
	background: #dddddd !important;
}

.snackbar-error {
	background: #f44336;
}

// used in select input option's label
.color-preview {
	display: inline-block;
	height: 16px;
	width: 16px;
	border-radius: 2px;
}

.mat-dialog-actions {
	justify-content: flex-end;
}
.mat-checkbox-layout {
	word-break: normal;
	white-space: normal !important;
}
.order-status-filter-select-panel-height {
	min-height: 420px !important;
}

.admin-order-status-filter-select-panel-height {
	min-height: 378px !important;
}

//.mat-dialog-container {
//	position: relative;
//	display: -ms-flexbox;
//	display: flex;
//	-ms-flex-direction: column;
//	flex-direction: column;
//	width: 100%;
//	pointer-events: auto;
//	background-color: #fff;
//	background-clip: padding-box;
//	border: 1px solid rgba(0, 0, 0, 0.2);
//	border-radius: 0.3rem;
//	outline: 0;
//}

.mat-select-panel {
	background-color: #fff;
}

.mat-table {
	background: #fff;
}
.mat-card {
	background: transparent;
}

.mat-select-notification-dropdown {
	max-height: 40px;
	border-radius: 20px !important;
}
