// default colors
:root {
	--aps-color-primary: #ff2453;
	--aps-color-secondary: #17a700;
	--aps-color-other: #bfbfbf;
	--aps-color-background: #f5f5f5;
}

$aps-color-primary: var(--aps-color-primary);
$aps-color-secondary: var(--aps-color-secondary);
$aps-color-other: var(--aps-color-other);
$aps-color-background: var(--aps-color-background);

$side-panel-width: 125px;
$side-panel-width-s: 100px;
$side-panel-width-xs: 80px;

$materials-bar-height: 110px;
$materials-bar-height-s: 90px;
$materials-bar-height-xs: 60px;

$colors-bar-height: 80px;
$colors-bar-height-s: 72px;
$colors-bar-height-xs: 65px;
