@import "admin-design.scss";
@import "src/styles/variables.scss";

.upper-section {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.upper-section .upper-text {
	flex-flow: column;
}

.upper-section .upper-text p {
	font-weight: bold;
	font-size: 31px;
	line-height: 120%;
	margin-bottom: 20px;
	margin-left: 60px;
}

.upper-section.form {
	margin-left: 0;
}

.upper-section.form i {
	margin-right: -120px;
}

.upper-section.form input {
	height: 50px;
	width: 380px;
}

.form {
	position: relative;
	display: flex;
	align-items: center;
	width: 315px;
	height: 50px;
	margin-left: 60px;
}

.right-side {
	display: flex;
	margin-bottom: -50px;
	margin-right: 60px;
}

.table {
	margin: 30px 0px;
}

.table-content {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

thead tr th {
	border-top: none !important;
	border-bottom: none !important;
	width: auto;
	padding-top: 20px !important;
	padding-bottom: 20px !important;
}

.table tr td:first-child {
	// font-weight: bold !important;
	// font-family: "Roboto", sans-serif !important;
	// font-size: 16px !important;
}

.table td,
.table th {
	vertical-align: 0;
}

.table td,
.table th {
	padding: 0.75rem !important;
	vertical-align: top !important;
	border-top: 1px solid #dee2e6;
}

.mdc-icon-button.icon-green {
	background-color: var(--aps-color-secondary);
}

//.icon-green {
//	display: flex;
//	justify-content: center;
//	align-items: center;
//	border-radius: 50%;
//	background-color: var(--aps-color-secondary);
//	width: 40px;
//	height: 40px;
//	color: #ffffff;
//	// font-size: 20px;
//	cursor: pointer;
//	box-shadow: 0px 10px 20px rgb(0 0 0 / 10%);
//	margin-right: -10px;
//}

.icon-green .mat-icon {
	color: #ffffff;
}

.mdc-icon-button.icon-red {
	background-color: var(--aps-color-primary);
}

//.icon-red {
//	display: flex;
//	justify-content: center;
//	align-items: center;
//	border-radius: 50%;
//	background-color: #{$aps-color-primary};
//	width: 40px;
//	height: 40px;
//	color: #ffffff;
//	font-size: 18px;
//	cursor: pointer;
//	box-shadow: 0px 10px 20px rgb(0 0 0 / 10%);
//	margin-left: 0.75rem !important;
//}

.icon-red .mat-icon {
	color: #ffffff;
}

.mdc-icon-button.icon-black {
	background-color: var(--aps-color-other);
}

//.icon-black {
//	display: flex;
//	justify-content: center;
//	align-items: center;
//	border-radius: 50%;
//	background-color: #{$aps-color-other} !important;
//	width: 40px;
//	height: 40px;
//	color: #ffffff;
//	font-size: 18px;
//	cursor: pointer;
//	box-shadow: 0px 10px 20px rgb(0 0 0 / 10%);
//	margin-left: 0.75rem !important;
//}

.icon-black .mat-icon {
	color: #ffffff;
}

.filter {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	letter-spacing: 1px;
	width: 130px;
	height: 40px;
	border: none;
	background-color: #{$aps-color-primary};
	color: #ffffff;
	box-shadow: 0px 6px 14px rgb(255 36 83 / 24%);
	border-radius: 50px;
}

.btn-admin-top {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	letter-spacing: 1px;
	height: 40px;
	border: none;
	background-color: #{$aps-color-primary} !important;
	color: #ffffff;
	box-shadow: 0px 6px 14px rgb(255 36 83 / 24%);
	border-radius: 50px !important;
}
