.padding-zero {
	padding: 0px !important;
}

.component-details {
	border-top: 1px solid #ffbaba !important;
	padding-top: 10px !important;
}

.mat-tab-body {
	padding: 20px 10px !important;
}
