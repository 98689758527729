@import "src/styles/variables.scss";

.mat-form-field-outline {
	background: #ffffff;
	box-shadow: 0px 4px 20px rgba(221, 221, 221, 0.2);
	border-radius: 10px;
}

.search-admin .mat-icon {
	position: absolute;
	top: -8px;
	right: 14px;
	font-size: 21px;
	cursor: pointer;
	color: #ffffff;
	background-color: #{$aps-color-primary};
	padding: 9px 11px;
	box-shadow: 0px 6px 14px rgb(255 36 83 / 24%);
	border-radius: 10px;
	width: 40px;
	height: 40px !important;
}

.btn-custom-default {
	background: #{$aps-color-other} !important;
	box-shadow: 0px 4px 4px rgba(191, 191, 191, 0.33) !important;
	border-radius: 50px !important;
	color: white !important;
}

.btn-custom-primary {
	background: #{$aps-color-secondary} !important;
	box-shadow: 0px 6px 14px rgba(23, 167, 0, 0.24) !important;
	border-radius: 50px !important;
	color: white !important;
}

.btn-custom-primary-disabled {
	background: gray !important;
	box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.24) !important;
	border-radius: 50px !important;
	color: white !important;
}

// .table-actions {
//   text-align: center;
// }

.mat-dialog-content::-webkit-scrollbar,
.mat-select-panel::-webkit-scrollbar {
	width: 0.3rem !important;
	transition: 300ms ease !important;
}

.mat-dialog-content::-webkit-scrollbar-track,
.mat-select-panel::-webkit-scrollbar-track {
	background: none !important;
	margin: 0px !important;
}

.mat-dialog-content::-webkit-scrollbar-thumb,
.mat-select-panel::-webkit-scrollbar-thumb {
	background: #cccccc !important;
}

.mat-dialog-content::-webkit-scrollbar-thumb:hover,
.mat-select-panel::-webkit-scrollbar-thumb:hover {
	background: #dddddd !important;
}

button:focus {
	outline: none !important;
}

@media only screen and (max-width: 768px) {
	.upper-section {
		flex-direction: column !important;
		align-items: unset;
		padding: 22px !important;
		width: 100vw;
	}
	.upper-text,
	.upper-text .form {
		margin-left: 0px;
		margin-right: 0px;
		width: calc(100vw - 44px);
	}
	.upper-text,
	.upper-text p {
		margin-left: 0px !important;
		margin-right: 0px !important;
	}
	.upper-section .right-side {
		margin-left: 0px;
		margin-right: 0px;
		margin: 16px 0px !important;
	}

	.upper-section .right-side > * {
		width: 100vw !important;
		max-width: 100vw !important;
	}
	.upper-section .right-side > button {
		width: 50vw !important;
		max-width: 50vw !important;
	}

	.table-container {
		margin: 0px;
		width: 100vw;
		overflow-x: scroll;
	}
	.mat-column-actions {
		width: 200px !important;
		display: block !important;
	}

	.mat-column-actions .mat-icon-button {
		width: 35px;
		height: 35px;
		flex-shrink: 0;
		line-height: 25px;
	}

	.mat-card {
		padding: 0px !important;
	}
}
